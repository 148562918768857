import React, { useEffect, useState } from "react";
import { flexRender, getCoreRowModel, getSortedRowModel, useReactTable } from "@tanstack/react-table";
import { useParams } from "react-router-dom";
import api from "../logic/apiHandler";
import { Helmet } from "react-helmet-async";
import { Icon } from "semantic-ui-react";
import Dimmer from "./Dimmer";
import { generatePath, Link } from "react-router-dom";

export default function WalkPDSummaryTable({ areaName }) {
  const [walkData, setWalkData] = useState([]);
  const [dataLoaded, setDataLoaded] = useState(false);
  const [error, setError] = useState(null);
  const { areaID } = useParams();

  useEffect(() => {
    (async () => {
      setDataLoaded(false);
      setError(null);

      try {
        const res = await api.seatData.walkSummary(areaID);
        setWalkData(getTableStats(res));
        setDataLoaded(true);
      } catch (e) {
        setError(e);
        setDataLoaded(true);
      }
    })();
  }, [areaID]);

  function getTableStats(data) {
    return data.map((row) => {
      return {
        ...row,
        percentageWalksWithDeliverers:
          row.walks === 0 ? "-" : `${Math.floor((row.walksWithDeliverers / row.walks) * 100)}%`,
        percentageDoorsWithDeliverers:
          row.doorCount === 0 ? "-" : `${Math.floor((row.doorsWithDeliverer / row.doorCount) * 100)}%`,
        percentageLeafletsWithDeliverer:
          row.leafletCount === 0 ? "-" : `${Math.floor((row.leafletsWithDeliverer / row.leafletCount) * 100)}%`,
        doorsPerWalk: row.walks === 0 ? "-" : Math.ceil(row.doorCount / row.walks),
        leafletsPerWalk: row.walks === 0 ? "-" : Math.floor(row.leafletCount / row.walks),
      };
    });
  }

  const columns = [
    {
      header: "Local Authority",
      accessorKey: "District_Borough_Unitary_Region_Name",
    },
    {
      header: "PD",
      accessorKey: "POLLING_DISTRICT_NUMBER",
      cell: ({ row }) => (
        <Link to={`${row.original.REGISTER_AREA.toString()}/${row.original.POLLING_DISTRICT_NUMBER}`}>
          {row.original.POLLING_DISTRICT_NUMBER}
        </Link>
      ),
    },
    {
      header: "Walks",
      accessorKey: "walks",
    },
    {
      header: "Walks with Deliverers",
      accessorKey: "walksWithDeliverers",
    },
    {
      header: "Geo Voter Count",
      accessorKey: "geoVoterCount",
    },
    {
      header: "Geo Voter Property Count",
      accessorKey: "geoVoterPropertyCount",
    },
    {
      header: "Person Count",
      accessorKey: "personCount",
    },
    {
      header: "Door Count",
      accessorKey: "doorCount",
    },
    {
      header: "Leaflet Count",
      accessorKey: "leafletCount",
    },
    {
      header: "Doors With Deliverer",
      accessorKey: "doorsWithDeliverer",
    },
    {
      header: "Leaflets With Deliverer",
      accessorKey: "leafletsWithDeliverer",
    },
    {
      header: "Percentage Walks With Deliverer",
      accessorKey: "percentageWalksWithDeliverers",
    },
    {
      header: "Percentage Doors With Deliverer",
      accessorKey: "percentageDoorsWithDeliverers",
    },
    {
      header: "Percentage Leaflets With Deliverer",
      accessorKey: "percentageLeafletsWithDeliverer",
    },
    { header: "Doors Per Walk", accessorKey: "doorsPerWalk" },
    {
      header: "Leaflets Per Walk",
      accessorKey: "leafletsPerWalk",
    },
  ];

  const table = useReactTable({
    columns,
    data: walkData,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
  });

  return (
    <div>
      <Helmet>
        <title>{areaName ?? ""} - Walk Summary</title>
      </Helmet>
      <Dimmer error={error} dataLoaded={dataLoaded}>
        <table>
          <thead>
            {table.getHeaderGroups().map((headerGroup) => (
              <tr key={headerGroup.id}>
                {headerGroup.headers.map((header) => (
                  <th key={header.id} colSpan={header.colSpan}>
                    <div
                      className={header.column.getCanSort() ? "cursor-pointer select-none" : ""}
                      onClick={header.column.getToggleSortingHandler()}
                      title={
                        header.column.getCanSort()
                          ? header.column.getNextSortingOrder() === "asc"
                            ? "Sort ascending"
                            : header.column.getNextSortingOrder() === "desc"
                            ? "Sort descending"
                            : "Clear sort"
                          : undefined
                      }
                    >
                      {flexRender(header.column.columnDef.header, header.getContext())}
                      {{
                        asc: <Icon name="caret up" color="yellow" />,
                        desc: <Icon name="caret down" color="yellow" />,
                      }[header.column.getIsSorted()] ?? null}
                    </div>
                  </th>
                ))}
              </tr>
            ))}
          </thead>

          <tbody>
            {table.getRowModel().rows.map((row) => {
              return (
                <tr key={row.id}>
                  {row.getVisibleCells().map((cell) => {
                    return <td key={cell.id}>{flexRender(cell.column.columnDef.cell, cell.getContext())}</td>;
                  })}
                </tr>
              );
            })}
          </tbody>
        </table>
      </Dimmer>
    </div>
  );
}
