import React, { useEffect, useState } from "react";
import { Grid, GridRow, GridColumn, Header, StatisticValue, StatisticLabel, Statistic } from "semantic-ui-react";
import { Helmet } from "react-helmet-async";
import { BarChart, Bar, XAxis, YAxis, ReferenceLine, ResponsiveContainer, Label } from "recharts";
import { useParams } from "react-router-dom";
import api from "../logic/apiHandler";
import Dimmer from "./Dimmer";

export default function EmailUsage() {
  const [chartData, setChartData] = useState([]);
  const [emailStats, setEmailStats] = useState({});
  const [dataLoaded, setDataLoaded] = useState(false);
  const [error, setError] = useState(null);
  const { committeeID } = useParams();

  useEffect(() => {
    (async () => {
      setDataLoaded(false);
      setError(null);

      try {
        const res = await api.committeeData.emailUse(committeeID);
        setChartData(getChartData(res));
        setEmailStats({
          totalSubs: res.lastContacted.map((i) => i.count).reduce((a, b) => a + b),
          neverContacted: res.lastContacted.map((i) => (i.months === null ? i.count : null)).reduce((a, b) => a + b),
          outOfRetention: res.lastContacted.map((i) => (i.months > 12 ? i.count : null)).reduce((a, b) => a + b),
          nearlyOutOfRetention: res.lastContacted
            .map((i) => (i.months > 9 && i.months <= 12 ? i.count : null))
            .reduce((a, b) => a + b),
        });
        setDataLoaded(true);
      } catch (e) {
        setError(e);
        setDataLoaded(true);
      }
    })();
  }, [committeeID]);

  function getChartData(data) {
    let graphingData = [];
    for (let i = 0; i < 14; i++) {
      let month = data.lastContacted.find((row) => row.months === i);
      if (month) {
        graphingData.push({ months: i + 1, count: month.count, recentlySubscribed: month.recentlySubscribed });
      } else {
        graphingData.push({ months: i + 1, count: 0, recentlySubscribed: 0 });
      }
    }

    const monthsFifteenPlus = data.lastContacted.filter((row) => row.months >= 14);
    graphingData.push({
      months: 15,
      count: monthsFifteenPlus.reduce((acc, curr) => acc + parseInt(curr.count), 0),
      recentlySubscribed: monthsFifteenPlus.reduce((acc, curr) => acc + parseInt(curr.recentlySubscribed), 0),
    });

    const neverContacted = data.lastContacted.find((row) => row.months === null);
    graphingData.push({ ...neverContacted, months: 16 });

    return graphingData.sort((a, b) => {
      return a.months - b.months;
    });
  }

  const CustomTick = ({ x, y, payload }) => {
    let value = payload.value;
    if (value === 0 || value === 17) {
      value = "";
    } else if (value === 15) {
      value = "15+";
    }

    if (value === 16) {
      return (
        <g transform={`translate(${x},${y})`}>
          <text x={0} y={0} dy={16}>
            <tspan textAnchor="middle" x="0">
              Never Been
            </tspan>
            <tspan textAnchor="middle" x="0" dy="20">
              Contacted
            </tspan>
          </text>
        </g>
      );
    } else {
      return (
        <g transform={`translate(${x},${y})`}>
          <text x={0} y={0} dy={16} textAnchor="middle">
            {value}
          </text>
        </g>
      );
    }
  };

  return (
    <div>
      <Helmet>
        <title>Email Usage</title>
      </Helmet>

      <Header as="h2">Email Usage</Header>
      <Dimmer error={error} dataLoaded={dataLoaded}>
        <div>
          <Grid>
            <GridRow>
              <GridColumn width={4}>
                <Statistic size="tiny">
                  <StatisticValue>{emailStats.totalSubs}</StatisticValue>
                  <StatisticLabel>total subscribed email addresses</StatisticLabel>
                </Statistic>
              </GridColumn>
              <GridColumn width={4}>
                <Statistic size="tiny">
                  <StatisticValue>{emailStats.neverContacted}</StatisticValue>
                  <StatisticLabel>never been emailed</StatisticLabel>
                </Statistic>
              </GridColumn>
              <GridColumn width={4}>
                <Statistic size="tiny">
                  <StatisticValue>{emailStats.outOfRetention}</StatisticValue>
                  <StatisticLabel>out of retention</StatisticLabel>
                </Statistic>
              </GridColumn>
              <GridColumn width={4}>
                <Statistic size="tiny">
                  <StatisticValue>{emailStats.nearlyOutOfRetention}</StatisticValue>
                  <StatisticLabel>nearly out of retention</StatisticLabel>
                </Statistic>
              </GridColumn>
            </GridRow>
          </Grid>
          <ResponsiveContainer aspect={2} minWidth={400} maxHeight={400} style={{ marginTop: "40px" }}>
            <BarChart data={chartData}>
              <XAxis
                dataKey="months"
                type="number"
                interval={0}
                domain={[0, 17]}
                tick={<CustomTick />}
                ticks={[0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17]}
                height={40}
                label={{ value: "Months", offset: 0, position: "insideBottom" }}
              />
              <YAxis label={{ value: "# of email addresses", angle: -90, position: "insideLeft" }} />
              <Bar dataKey="count" stackId="a" fill="#FAA61A" />
              <Bar dataKey="recentlySubscribed" stackId="a" fill="#000000" />
              <ReferenceLine x={9.5} isFront={false} stroke="red" strokeDasharray="3 3" />
              <ReferenceLine x={12.5} isFront={false} stroke="red" strokeDasharray="3 3" />
            </BarChart>
          </ResponsiveContainer>
        </div>
      </Dimmer>
    </div>
  );
}
