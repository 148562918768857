import React, { useState } from "react";
import {
  FormButton,
  FormInput,
  ButtonGroup,
  Input,
  Form,
  FormGroup,
  FormField,
  Grid,
  GridColumn,
  GridRow,
  Header,
  Message,
} from "semantic-ui-react";
import { userManage } from "../logic/apiHandler";
import { useNavigate } from "react-router-dom";

export default function AddUser() {
  let navigate = useNavigate();
  const [forename, setForename] = useState("");
  const [surname, setSurname] = useState("");
  const [email, setEmail] = useState("");
  const [error, setError] = useState(null);

  async function handleSubmit() {
    try {
      const { data } = await userManage.addUser({ forename, surname, email });
      navigate(`/users/edit/${data.userID}`);
    } catch (e) {
      console.log(e);
      setError(e);
    }
  }

  return (
    <div style={{ margin: "10px" }}>
      <Header as="h2">Add New User:</Header>
      <Form>
        <Grid stackable>
          <GridRow style={{ marginTop: "10px" }}>
            <GridColumn>
              <FormGroup widths="equal">
                <FormField
                  control={Input}
                  label="Forename"
                  placeholder="Forename"
                  value={forename}
                  onChange={(e) => setForename(e.target.value)}
                />
                <FormField
                  control={Input}
                  label="Surname"
                  placeholder="Surname"
                  value={surname}
                  onChange={(e) => setSurname(e.target.value)}
                />
                <FormField
                  control={Input}
                  label="Email"
                  placeholder="Email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
              </FormGroup>
            </GridColumn>
          </GridRow>
          <GridRow>
            {error ? (
              <Message error header={"Cannot complete your request at this time; please try again later"} />
            ) : (
              <></>
            )}
          </GridRow>
          <GridRow>
            <GridColumn>
              <ButtonGroup>
                <FormButton
                  disabled={!forename || !surname || !email}
                  style={{ backgroundColor: "#FAA61A" }}
                  onClick={() => handleSubmit()}
                >
                  Submit
                </FormButton>
                <FormButton onClick={() => navigate(-1)}>Cancel</FormButton>
              </ButtonGroup>
            </GridColumn>
          </GridRow>
        </Grid>
      </Form>
    </div>
  );
}
