import React, { useContext, useEffect, useState } from "react";
import { generatePath, Link, NavLink, Route, Routes, useParams } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import { Header, Menu, MenuItem } from "semantic-ui-react";
import { canAccess } from "../logic/userPermissionsHandler";
import { UserContext } from "./UserContext";
import api from "../logic/apiHandler";

import EmailUsage from "./EmailUsage";
import CommitteeUsersList from "./CommitteeUsersList";
import DropdownSearchMenu from "./DropdownSearchMenu";

export default function CommitteeData() {
  const { committeeID } = useParams();
  const user = useContext(UserContext);
  const [committees, setCommittees] = useState([]);
  const [committeeName, setCommitteeName] = useState(committeeID);
  const params = useParams();

  useEffect(() => {
    (async () => {
      try {
        const committeeInfo = await api.committeeData.info(committeeID);
        setCommitteeName(committeeInfo.committeeName);
      } catch (e) {
        console.log("e", e);
      }
    })();

    (async () => {
      try {
        setCommittees(await api.committeeData.list());
      } catch (e) {
        console.log("e", e);
      }
    })();
  }, [committeeID]);

  function formatDropdownOptions() {
    return committees.map((committee) => {
      return {
        as: Link,
        key: committee.committeeID,
        text: committee.committeeName,
        to: generatePath("/committeeData/:committeeID/*", { ...params, committeeID: committee.committeeID }),
        value: generatePath("/committeeData/:committeeID/*", { ...params, committeeID: committee.committeeID }),
      };
    });
  }

  return (
    <div>
      <Helmet>
        <title>Committee Data - {committeeName ?? committeeID}</title>
      </Helmet>
      <Header as="h2">Committee data - {committeeName ?? committeeID}</Header>
      <Menu secondary size="small" borderless pointing>
        {canAccess(user, "basicCanvassing") ? (
          <MenuItem as={NavLink} to="./committeeUsers" name="committee-users">
            Committee Users
          </MenuItem>
        ) : (
          <></>
        )}

        {canAccess(user, "basicCanvassing") ? (
          <MenuItem as={NavLink} to="./emailUsage" name="email-usage">
            Email Usage
          </MenuItem>
        ) : (
          <></>
        )}

        <MenuItem>
          <DropdownSearchMenu options={formatDropdownOptions()} />
        </MenuItem>
      </Menu>

      <Routes>
        <Route path="/" element={<div>Hello - you're at {committeeName ?? committeeID}</div>} />
        <Route path="/committeeUsers" element={<CommitteeUsersList />} />
        <Route path="/emailUsage" element={<EmailUsage />} />
        <Route
          path="*"
          element={
            <div>
              <Header as="h1">404</Header>You are nowhere
            </div>
          }
        />
      </Routes>
    </div>
  );
}
