import React, { useMemo } from "react";
import { flexRender, getCoreRowModel, useReactTable } from "@tanstack/react-table";
import { Icon } from "semantic-ui-react";

export default function UserDistrictAccessTable({ setSelectedDistricts, userDistricts }) {
  const columns = useMemo(
    () => [
      {
        header: "Area",
        accessorKey: "name",
      },
      {
        header: "Type",
        accessorKey: "fieldcode",
        cell: ({ row }) => getTitle(row.original.districts),
      },
      {
        header: "Remove",
        accessorKey: "code",
        cell: ({ row }) => (
          <Icon
            name="delete"
            color="yellow"
            onClick={() =>
              setSelectedDistricts((prev) => [
                ...prev.filter((constituency) => constituency.code !== row.original.code),
              ])
            }
          />
        ),
      },
    ],
    []
  );

  function getTitle(type) {
    switch (type) {
      case "W":
        return "Old Westminster";
      case "W2024":
        return "New Westminster";
      case "ER":
        return "European region";
      case "DevC":
        return "Devolved constituency";
      case "DevR":
        return "Devolved region";
      case "DBUWR":
        return "Ward";
      case "DBU":
        return "Local authority";
      case "CR":
        return "County council";
      case "CEDR":
        return "County division";
      default:
        let words = type.split(/(?=[A-Z])/);
        return words
          .map((word) => {
            return word[0].toUpperCase() + word.substring(1);
          })
          .join(" ");
    }
  }

  const table = useReactTable({
    columns,
    data: userDistricts,
    getCoreRowModel: getCoreRowModel(),
  });

  return (
    <div>
      <h3>Current area access permissions</h3>
      <table style={{ minWidth: "350px" }}>
        <thead>
          {table.getHeaderGroups().map((headerGroup) => (
            <tr key={headerGroup.id}>
              {headerGroup.headers.map((header) => (
                <th key={header.id} colSpan={header.colSpan}>
                  {flexRender(header.column.columnDef.header, header.getContext())}
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody>
          {table.getRowModel().rows.map((row) => {
            return (
              <tr key={row.id}>
                {row.getVisibleCells().map((cell) => {
                  return <td key={cell.id}>{flexRender(cell.column.columnDef.cell, cell.getContext())}</td>;
                })}
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
}
